// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-on-apple-tv-js": () => import("./../../../src/pages/on-apple-tv.js" /* webpackChunkName: "component---src-pages-on-apple-tv-js" */),
  "component---src-pages-on-ipad-js": () => import("./../../../src/pages/on-ipad.js" /* webpackChunkName: "component---src-pages-on-ipad-js" */),
  "component---src-pages-on-iphone-js": () => import("./../../../src/pages/on-iphone.js" /* webpackChunkName: "component---src-pages-on-iphone-js" */),
  "component---src-pages-on-mac-js": () => import("./../../../src/pages/on-mac.js" /* webpackChunkName: "component---src-pages-on-mac-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */)
}

